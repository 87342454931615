<template>
  <div class="page">
    <BreadCrumb />
    <el-button class="goBack" plain round size="mini" type="primary" @click="goBack"> 返回 </el-button>
    <div class="page-body">
      <div class="step">
        <el-steps :active="active" class="step_step" finish-status="success">
          <el-step title="基本配置" />
          <el-step title="输入配置" />
          <el-step title="输出配置" />
          <el-step title="规则绑定" />
          <el-step title="告警规则" />
          <el-step title="作业预览" />
        </el-steps>
      </div>
      <el-form ref="workForm" :model="workForm" :rules="workRules" class="step_form" label-width="80px">
        <div v-show="active == 1" class="form0 is-required">
          <el-form-item label="作业名称" prop="workName">
            <el-input id="workName" v-model="workForm.workName" placeholder="请输入作业名称" type="text" />
          </el-form-item>
          <el-form-item label="触发方式" prop="triggerMethod">
            <el-select v-model="workForm.triggerMethod" placeholder="请选择触发方式" @change="gettriggerMethod">
              <el-option v-for="(item, index) of triggerMethodLists" :key="index" :label="index" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="istriggerMethod" label="调度周期" prop="workCron">
            <el-select v-model="workForm.workCron" placeholder="请选择调度周期">
              <el-option v-for="(item, index) of workCronLists" :key="index" :label="index" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="istriggerMethod" label="生效时间" prop="timeCond">
            <el-date-picker @change='timeChange(workForm.timeCond)'  v-model="workForm.timeCond" end-placeholder="结束日期" range-separator="至" start-placeholder="开始日期" type="daterange"  />
          </el-form-item>
        </div>
        <div v-show="active == 2" class="form1 .box1 form-inline">
          <el-form-item label="稽核方式" prop="checkMode">
            <el-select v-model="workForm.checkMode" placeholder="请选择稽核方式">
              <el-option v-for="(item, index) of checkModeLists" :key="index" :label="index" :value="item" />
            </el-select>
          </el-form-item>
          
          
          <el-form ref="workInputMetas" :model="workInputMetas" class="workInputMetas">
            <el-form-item v-for="(item, index) in workInputMetas" :key="index" class="div1">
              <!-- 选择库 -->
              <el-select v-model="item.dsId"  :class="{ isHide: item.dsIdHide }" placeholder="请选择库">
                <el-option v-for="(list, index1) of item.dsIdList" :disabled="list.disabled" :key="index1" :label="list.dsDesc? list.dsDesc : list.dsName" :value="list.dsId" @click="dsIdListClick(item, list,index)" />
              </el-select>
              <!-- 选择表 -->
              <el-select v-model="item.tabId" filterable  placeholder="请选择表" @change="tabIdListChange(item,index)">
                <!-- list.tabDesc?list.tabDesc:list.tabName -->
                <el-option v-for="(list, index1) of item.tabIdList" :disabled="list.disabled" :key="index1" :label="list.tabDesc ? list.tabDesc : list.tabName" :value="list.tabId" @click="tabIdListClick(item, list)" />
              </el-select>
              <el-select v-model="item.workInputMetaOutputCols" class="flex1" multiple placeholder="请选择列">
                <el-option v-for="(list, index1) of item.workInputMetaOutputColsList" :key="index1" :label="list.colDesc?list.colDesc:list.colName" :value="list.colId" />
              </el-select>
              <el-form-item class="text-center">
                <el-button  type="primary" @click="addLibrary0(item, index)"> 添加 </el-button>
              </el-form-item>
              <el-form-item :class="{ isHide: index < 1 }">
                <el-button @click.prevent="removeLibrary(item,index)"> 删除 </el-button>
              </el-form-item>
            </el-form-item>
            <el-form-item class="text-center">
              <el-button type="primary" @click="addLibrary1"> 添加 </el-button>
            </el-form-item>
          </el-form>
          
          <el-form ref="workInputMetaRelations" :model="workInputMetaRelations" class="workInputMetaRelations">
            <el-form-item v-for="(item, index) in workInputMetaRelations" :key="index">
              <el-select v-model="item.leftDsId" placeholder="请选择库">
                <el-option v-for="(list, index1) of item.leftDsIdList" :key="index1" :label="list.dsDesc" :value="list.dsId" @click="dsIdClick(item, list, 'lt')" />
              </el-select>
          
              <el-select v-model="item.leftTabId" placeholder="请选择表">
                <el-option v-for="(list, index1) of item.leftTabIdList" :key="index1" :label="list.tabDesc ? list.tabDesc : list.tabName" :value="list.tabId" @click="tabIdClick(item, list, 'lt')" />
              </el-select>
          
              <el-select v-model="item.leftColId" placeholder="请选择列">
                <el-option v-for="(list, index1) of item.leftColIdList" :key="index1" :label="list.colDesc?list.colDesc:list.colName" :value="list.colId" />
              </el-select>
          
              <el-select v-model="item.joinCond" placeholder="请选择关联关系">
                <el-option v-for="(list, index1) of item.joinCondList" :key="index1" :label="list.name" :value="list.id" />
              </el-select>
              <el-select v-model="item.rightDsId" placeholder="请选择库">
                <el-option v-for="(list, index1) of item.rightDsIdList" :key="index1" :label="list.dsDesc" :value="list.dsId" @click="dsIdClick(item, list, 'rt')" />
              </el-select>
              <el-select v-model="item.rightTabId" placeholder="请选择表">
                <el-option v-for="(list, index1) of item.rightTabIdList" :key="index1" :label="list.tabDesc ? list.tabDesc : list.tabName" :value="list.tabId" @click="tabIdClick(item, list, 'rt')" />
              </el-select>
              <el-select v-model="item.rightColId" placeholder="请选择列">
                <el-option v-for="(list, index1) of item.rightColIdList" :key="index1" :label="list.colDesc?list.colDesc:list.colName" :value="list.colId" />
              </el-select>
              
              
            </el-form-item>
          </el-form>
          
        </div>
        <div v-show="active == 3" class="form2 is-required">
          <el-form-item label="入库方式" prop="writeMode">
            <el-select v-model="workForm.writeMode" placeholder="请选择入库方式">
              <el-option v-for="(item, index) of writeModeLists" :key="index" :label="index" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="目标库" prop="tarLib">
            <el-checkbox-group v-model="workForm.tarLib">
              <el-checkbox v-for="(item, index) of auditLists" :key="index" :label="item.dsId" name="tarLib">
                {{ item.dsDesc }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
        <div v-show="active == 4" class="form3">
          <div class="flex1">
    
            <div>
              <el-button class="margin-10-0"  type="primary" @click="ruleDialog()"> 绑定规则 </el-button>
            </div>
            
            <ul id="myTab" class="nav nav-tabs" />
            <div id="myTabContent" class="tab-content" />
          </div>
          <el-tabs v-model="activeName">
                 <el-tab-pane v-for="(item, index) of onceArr" :key="index" :label="item.dsDesc" :name="item.dsId" >
                    <el-tabs v-model="activeName1">
                        <el-tab-pane  v-for="(item1, index1) of item.child" :key="index1" :label="item1.tabDesc" :name="item1.tabId">
                            <el-table :data="item1.tableData"  stripe border fit v-loading="loading">
                              <el-table-column prop="colDesc" label="中文名称" ></el-table-column>
                              <el-table-column prop="colName" label="英文名称"></el-table-column>
                              <el-table-column prop="colDatatype" label="字段类型"></el-table-column>
                              <el-table-column prop="text" label="关联规则" ></el-table-column>
                              <el-table-column  label="操作">
                                <template #default="scope">
                                <el-tooltip class="item" effect="light" content="预览" placement="top-end">
                                  <el-button type="primary" circle  size="mini"  @click="previewRule(item1.tableData,scope.row)" >
                                    <el-icon :size="15">
                                      <i-view />
                                    </el-icon>
                                  </el-button>
                                </el-tooltip>
                                
                                <el-tooltip class="item" effect="light" content="编辑" placement="top-end">
                                  <el-button type="primary" circle  size="mini"  @click="editRule(scope.$index,scope.row)" >
                                    <el-icon :size="15">
                                      <i-edit />
                                    </el-icon>
                                  </el-button>
                                </el-tooltip>
                                
                                  <el-popconfirm cancel-button-text="取消" confirm-button-text="确定" icon-color="red" size="mini" title="确定要删除么?" @confirm="deleteRule(item1.tableData,scope.$index)">
                                    <template #reference>
                                      <el-button circle size="mini" title="删除" type="danger">
                                        <el-icon>
                                          <i-delete />
                                        </el-icon>
                                      </el-button>
                                    </template>
                                  </el-popconfirm>
                                  
                                </template>
                              </el-table-column>
                            </el-table>
                        </el-tab-pane>
                    </el-tabs>
              </el-tab-pane>
          </el-tabs>

          <el-dialog v-model="dialog.isShow" :title="dialog.title">
            <el-row  :class="{ disabled: dialog.rulesDisabled }">
                <el-col :span="8">
                   <el-tree :data="setTree" :props="defaultProps" highlight-current @node-click="handleNodeClick" />
                </el-col>
                <el-col :span="16">
                    <el-form-item label="数据源"  :class="{ disabled: dialog.rulesEditDisabled }">
                      <el-select v-model="onceRuleDialog.t1" placeholder="请选择数据源">
                        <el-option v-for="(item, index) of onceArr" :key="index" :label="item.dsDesc" :value="item.dsId"  @click="onceRuleDialogClick1(item)" />
                      </el-select>
                    </el-form-item>
                    
                    <el-form-item label="表"   :class="{ disabled: dialog.rulesEditDisabled }">
                      <el-select v-model="onceRuleDialog.t2" placeholder="请选择表">
                        <el-option v-for="(item, index) of onceRuleDialog.list2" :key="index" :label="item.tabDesc? item.tabDesc : item.tabName" :value="item.tabId"  @click="onceRuleDialogClick2(item)" />
                      </el-select>
                    </el-form-item>
                    <el-form-item label="列">
                      <el-select v-model="onceRuleDialog.t3" placeholder="请选择列">
                        <el-option v-for="(item, index) of onceRuleDialog.list3" :key="index" :label="item.colDesc?item.colDesc:item.colName" :value="item.colId" @click="onceRuleDialogClick3(item)"/>
                      </el-select>
                    </el-form-item>
                    <el-form-item v-for="(item, index) of onceRuleDialog.list4" :key="index" :label="item.label">
                      <el-input v-model="item.value" :disabled="item.disabled" :placeholder="item.placeholder"></el-input>
                    </el-form-item>
                </el-col>
              </el-row>
           
            <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="ruleSubmit">确 定</el-button>
                <el-button @click="dialog.isShow = false">取 消</el-button>
              </span>
            </template>
          </el-dialog>
          
        </div>
        <div v-show="active == 5" class="form4 d-flex">
          <div class="rules">
            <div class="rules_div">
              <div>序号</div>
              <div>校验对象</div>
              <div>校验模板</div>
              <div>比对条件</div>
              <div>阈值</div>
              <div>告警级别</div>
              <div>自动生成质量问题</div>
              <div>操作</div>
            </div>
            <el-form ref="alarms" :model="alarms" class="demo-dynamic" label-width="120px">
              <el-form-item v-for="(item, index) in alarms" :key="index">
                <div>{{ index + 1 }}</div>
                <!-- 校验模板 -->
                <el-select v-model="item.checkObject" @change="TemplateChange(item)">
                  <el-option v-for="(list, index1) of item.CheckObjectEnum" :key="index1" :label="list.LABEL" :value="list.CODE" />
                </el-select>
                <!-- 校验对象 -->
                <el-select v-model="item.checkTemplate" @change="ObjectChange(item)">
                  <el-option v-for="(list, index1) of item.CheckTemplateEnum" :key="index1" :disabled="list.disabled" :label="list.LABEL" :value="list.CODE" />
                </el-select>
                <!-- 比对条件 -->
                <el-select v-model="item.compareType">
                  <el-option v-for="(list, index1) of item.CompareTypeEnum" :key="index1" :disabled="list.disabled" :label="list.LABEL" :value="list.CODE" />
                </el-select>
                <!-- 阈值 -->
                <el-input v-model="item.inputText" onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" placeholder="请输入阈值" @blur="thresholdBlur(item)" @focus="thresholdFocus(item)" />
                <!-- 告警级别 -->
                <el-select v-model="item.alarmLevel">
                  <el-option v-for="(list, index1) of item.AlarmLevelEnum" :key="index1" :label="list.LABEL" :value="list.CODE" />
                </el-select>
                <!-- 自动生成质量问题 -->
                <el-form-item>
                  <el-switch v-model="item.autoProblem" :active-value="1" :inactive-value="0"> </el-switch>
                </el-form-item>
                <el-form-item>
                  <el-button @click.prevent="removeDomain(item)"> 删除 </el-button>
                </el-form-item>
              </el-form-item>
              <el-form-item>
                <el-button @click="addDomain"> 添加 </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div v-show="active == 6" class="form5">
          <el-descriptions title="基础配置信息"   :column="5"  border  >
              <el-descriptions-item label="作业名称"> {{ workForm.workName }}</el-descriptions-item>
              <el-descriptions-item label="触发方式">{{ workForm.triggerMethod == 1001 ? '手动(MANUAL)' : '自动(AUTO)' }}</el-descriptions-item>
              <el-descriptions-item label="调度周期" v-if="istriggerMethod"> {{ workForm.workCronCn }}</el-descriptions-item>
              <el-descriptions-item label="生效开始时间" v-if="istriggerMethod"> {{allDatas['startTime']}}</el-descriptions-item>
              <el-descriptions-item label="生效结束时间" v-if="istriggerMethod"> {{ allDatas['endTime']}}</el-descriptions-item>
          </el-descriptions>
          
          <el-descriptions title="输入配置信息"   :column="3"  border  class="m-t-20">
             <el-descriptions-item label="稽核方式" :span="3"> {{ workForm.checkMode == 1010 ? '全量(FULL)' : '增量(INCR)' }}</el-descriptions-item>
               <div v-for="item in workInputMetas" :key="item">
                 <el-descriptions-item label="库"> {{ item.dsDesc}}</el-descriptions-item>
                 <el-descriptions-item label="表" > {{ item.tabDesc==""?item.tabName:item.tabDesc}}</el-descriptions-item>
                 <el-descriptions-item label="列"> 
                    <div v-for="list in item.workInputMetaOutputColsCn" :key="list" class="flex1">
                       {{ list.colDesc==""?list.colName:list.colDesc}}
                  </div>
                 </el-descriptions-item>
               </div>
          </el-descriptions>
          <el-descriptions title="表关系"   :column="7"  border  class="m-t-20">    
              <div v-for="(item, index) in workInputMetaRelations" :key="index">
                <el-descriptions-item label="库"> {{ item.leftdsDesc}}</el-descriptions-item>
                <el-descriptions-item label="表"> {{ item.lefttabDesc}}</el-descriptions-item>
                <el-descriptions-item label="列"> {{ item.leftcolDesc==""?item.leftcolName:item.leftcolDesc}}</el-descriptions-item>
                <el-descriptions-item label="连接条件"> {{ item.joinCondName}}</el-descriptions-item>
                <el-descriptions-item label="库"> {{ item.rightdsDesc}}</el-descriptions-item>
                <el-descriptions-item label="表"> {{ item.righttabDesc}}</el-descriptions-item>
                <el-descriptions-item label="列"> {{ item.rightcolDesc==""?item.rightcolName:item.rightcolDesc}}</el-descriptions-item>
              </div>
          </el-descriptions>
            
          <el-descriptions title="输出配置信息"   :column="2"  border  class="m-t-20">
            <el-descriptions-item label="入库方式"> {{ workForm.writeMode == 3001 ? '覆盖写(Overwrite)' : '追加写(Append)' }}</el-descriptions-item>
            <el-descriptions-item label="目标库" > 
              <div v-for="(item, index) in allDatas.workOutputMetas" :key="index">
                {{ item.dsDesc}}
              </div>
            </el-descriptions-item>
          </el-descriptions>
          
          <el-descriptions title="规则信息"   :column="3"  border  class="m-t-20">
            <div  v-for="item of allDatas.rules" :key="item">
             <el-descriptions-item label="模板主键"> {{ item.templateId }}</el-descriptions-item>
              <el-descriptions-item label="关联规则"> {{ item.text }}</el-descriptions-item>
              <el-descriptions-item label="字段类型"> {{ item.colDatatype }}</el-descriptions-item>
            </div>
          </el-descriptions>
          
          <el-descriptions title="告警规则"   :column="6"  border  class="m-t-20">
            <div  v-for="(item, index) in alarms" :key="index">
              <el-descriptions-item label="校验对象"> {{ item.checkObjectCn }}</el-descriptions-item>
              <el-descriptions-item label="校验模板"> {{ item.checkTemplateCn }}</el-descriptions-item>
              <el-descriptions-item label="比对条件"> {{ item.compareTypeCn }}</el-descriptions-item>
              <el-descriptions-item label="阈值"> {{ item.inputText }}</el-descriptions-item>
              <el-descriptions-item label="告警级别"> {{ item.alarmLevelCn }}</el-descriptions-item>
              <el-descriptions-item label="自动生成质量问题"> {{item.autoProblemCn}}</el-descriptions-item>
            </div>
          </el-descriptions>
          
        </div>
      </el-form>
      <div class="stepBtn">
        <el-button v-if="active > minActive" @click="prev('workForm')"> 上一步 </el-button>
        <el-button v-if="active < maxActive" @click="next('workForm')"> 下一步 </el-button>
        <el-button v-if="active === maxActive" type="primary" @click="postData()"> 保存 </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import '/src/assets/js/bootstrap/bootstrap.min.js' /*bootstrap*/
import BreadCrumb from '/src/components/BreadCrumb' //面包屑
export default {
  //注册组件
  components: {
    BreadCrumb,
  },
  data() {
    return {
      workId:"",//有值：修改， 否则是新建作业
      resDatasource:"",
      dialog: {
        isShow: false,
        isAdd: false,
        title: '添加字段',
        rulesDisabled:false,
        rulesEditDisabled:false,
      },
      //输入配置
      workInputMetas: [
        {
          dsId: '',
          tabId: '',
          workInputMetaOutputCols: [],
          dsIdList: [],
          tabIdList: [],
          workInputMetaOutputColsList: [],
          newLibrary:true,
        },
      ],
      workInputMetaRelations: [],
      onceWTMR: [], //暂时存 表 循环
      joinConds: [],
      alarms: [
        //告警规则
        {
          checkTemplate: '', //校验模板
          threshold: '', //阈值
          inputText:"",
          checkObject: '', //校验条件
          compareType: '', // 比对条件
          alarmLevel: '', //告警级别
          autoProblem: 0, //自动生成质量问题
          CheckObjectEnum: [],
          CheckTemplateEnum: [],
          CompareTypeEnum: [],
          AlarmLevelEnum: [],
        },
      ],
      CheckObjectEnum: [],
      CheckTemplateEnum: [],
      CompareTypeEnum: [],
      AlarmLevelEnum: [],
      onceArr:[{
        dsId: 6,
        dsDesc: "交通",
        tabId: 245,
        tabDesc: "sys_test",
        child:[{
          tabDesc: "sys_test",
          tabId: 245
        },
        {
          tabDesc: "aaat",
          tabId: 111
        }]
      },{child:[{
          tabDesc: "aaa",
          tabId: 121
        }],
        dsId: 2,
        dsDesc: "交通2",
        tabId: 222,
        tabDesc: "sys_test2"
      }],   // 步骤二 关联关系循环列表用
      onceRuleDialog:{
        t1:"",
        t2:"",
        t3:"",
        list2:[],
        list3:[],
        list4:[]
        },   // 步骤四 模态框用
      onceNewTable:{},
      onceTabId:'',
      ruleTabRadio:"",
      activeName:'',
      onceRuleState:"add",
      onceLib:[],
      onceTab:[],
      editIndex:"",//修改用的 index
      projectId: '', //作业主键
      triggerMethodLists: '', //触发方式 临时储存用
      workCronLists: '', //调度周期 临时储存用
      checkModeLists: '', //稽核方式 临时储存用
      auditLists: '', //稽核库 临时储存用
      auditFormLists: '', //稽核表 临时储存用
      auditColLists: '', //稽核列 临时储存用
      writeModeLists: '', //入库方式 临触发方式时储存用
      istriggerMethod: false, //默认 手动触发， 调度周期、生效时间 隐藏
      workForm: {
        workName: '', //作业名称
        triggerMethod: '', //作业触发方式
        workCron: '', //作业调度周期
        workCronCn:"",
        timeCond: [], //时间 临时储存用
        startTime: '', //作业开始时间
        endTime: '', //作业结束时间
        checkMode: '', //稽核方式
        audit: [], //稽核库
        auditForm: [], //稽核表
        auditCol: [], //稽核列
        writeMode: '', //入库方式
        tarLib: [], //目标库
      },
      allDatas:{}, //最后传给后台的数据
      // 表单验证，需要在 el-form-item 元素中增加 prop 属性
      workRules: {
        triggerMethod: [
          {
            required: true,
            message: '请选择触发方式',
            trigger: 'change',
          },
        ],
        workCron: [
          {
            required: true,
            message: '请选择调度周期',
            trigger: 'change',
          },
        ],
        timeCond: [
          {
            required: true,
            message: '请选择生效时间',
            trigger: 'change',
          },
        ],
        checkMode: [
          {
            required: true,
            message: '请选择稽核方式',
            trigger: 'change',
          },
        ],
        audit: [
          {
            required: true,
            message: '请选择稽核库',
            trigger: 'change',
          },
        ],
        writeMode: [
          {
            required: true,
            message: '请选择入库方式',
            trigger: 'change',
          },
        ],
      },
      active: 1, //当前
      minActive: 1, //最小
      maxActive: 6, //最大
      setTree: [],
      defaultProps: {
        //tree 数据格式
        children: 'child',
        label: 'text',
      },
    }
  },
  //挂在完成 此时一般可以做一些ajax操作，mounted只会执行一次。
  mounted: function () {
    /* eslint-disable */
    var _this = this;
    console.log(_this.$route.query)
    _this.workId = _this.$route.query.workId;
    _this.projectId = Number(this.$route.query.projectId)
    if(_this.workId== undefined){
      //作业数据源
      this.$axios.GET('/api/v1/core/project/datasource/all').then(function (res) {
        let data = res.data
        _this.workInputMetas[0].dsIdList = data
        _this.auditLists = res.data //仅 vue循环用
       
      })
    }else{
       _this.workId = Number(_this.$route.query.workId)
       _this.allDatas["workId"] = _this.workId;
       this.$axios.GET('/api/v1/core/project/work/' + _this.workId).then(res => {
         let data = res.data;
         //步骤一回显
         _this.workForm.workName = data.workName;
         _this.workForm.triggerMethod = data.triggerMethod;
         if(data.triggerMethod == 1002){
          _this.istriggerMethod=true;
          _this.workForm.workCron = data.workCron
          _this.workForm.timeCond[0] = data.startTime;
          _this.workForm.timeCond[1] = data.endTime;
          _this.allDatas['startTime'] = data.startTime;
          _this.allDatas['endTime'] = data.endTime;
         }
         //步骤二回显
         _this.workForm.checkMode = data.checkMode;
        // _this.workInputMetas = data.workInputMetaResponses;
         _this.workInputMetas=[]
         //作业数据源
         _this.$axios.GET('/api/v1/core/project/datasource/all').then(function (resDatasource) {
           _this.resDatasource = resDatasource.data;
           _this.auditLists = resDatasource.data //仅 vue循环用
           let onceWorkInputMetas ={}
           let aaaa =[]
           _this.onceLib=[]
           _this.onceTab=[]
           for(let item of data.workInputMetaResponses){
             onceWorkInputMetas ={}
             onceWorkInputMetas.dsIdList = _this.resDatasource
             onceWorkInputMetas.dsId = item.dsId.dsId;
             _this.onceLib.push(item.dsId.dsId)
             onceWorkInputMetas.dsDesc = item.dsId.dsDesc;
             onceWorkInputMetas.dsName = item.dsId.dsName;
             onceWorkInputMetas.tabIdList = item.dsId.metadataTabs
             onceWorkInputMetas.tabId = item.tabId.tabId;
             _this.onceTab.push( item.tabId.tabId)
             onceWorkInputMetas.tabDesc = item.tabId.tabDesc;
             onceWorkInputMetas.tabName = item.tabId.tabName;
             onceWorkInputMetas.workInputMetaOutputColsList = item.tabId.metadataCols
              aaaa =[]
             for(let list of item.workInputMetaOutputColResponses){
               aaaa.push(list.colId.colId)
             }
             onceWorkInputMetas.workInputMetaOutputCols = aaaa;
              _this.workInputMetas.push(onceWorkInputMetas)
           }
           for(let s of _this.workInputMetas){
              for(let list of s.dsIdList){
                 list.disabled = false
                   for(let item of _this.onceLib){
                       if(list.dsId ==item){
                         list.disabled = true
                       }
                   }
              }
              for(let list of s.tabIdList){
                 list.disabled = false
                   for(let item of _this.onceTab){
                       if(list.tabId ==item){
                         list.disabled = true
                       }
                   }
              }
           }
           let onceArrLet = JSON.parse(JSON.stringify(_this.workInputMetas))
           for (var i = 0, len = _this.workInputMetas.length; i < len; i++) {
             for (var j = i + 1, len = _this.workInputMetas.length; j < len; j++) {
               if (_this.workInputMetas[i].dsId ===_this.workInputMetas[j].dsId) {
                _this.workInputMetas[j]["dsIdHide"]=true
               }
             }
           }
             _this.onceArr = JSON.parse(JSON.stringify(_this.workInputMetas))
             let onceA = []
                for(let item of _this.onceArr){
                  item.child =[{
                    tabId:item.tabId,
                    tabDesc:item.tabDesc,
                    tableData:[]
                  }]
                }
                for (var i = 0, len = _this.onceArr.length; i < len; i++) {
                  for (var j = i + 1, len = _this.onceArr.length; j < len; j++) {
                    if (_this.onceArr[i].dsId === _this.onceArr[j].dsId) {
                     onceA={
                       tabId:_this.onceArr[j].tabId,
                       tabDesc:_this.onceArr[j].tabDesc,
                       tableData:[]
                     }
                     _this.onceArr[i]["child"].push(onceA);
                     _this.onceArr.splice(j, 1);
                     j--; // 每删除一个数j的值就减1
                     len--; // j值减小时len也要相应减1（减少循环次数，节省性能）
                    }
                  }
                }
            _this.workInputMetaRelations=[];
                    let newlist = [];
                    _this.onceArr.map(function(items) {
                      newlist.push({
                        dsId:items.dsId,
                        dsDesc:items.dsDesc
                      })
                    });
                     let onceLeftTabIdList = []
                    let oncerightTabIdList = []
                    let onceleftColIdList = []
                     let oncerightColIdList = []
                    for(let i=0;i<data.workInputMetaRelationResponses.length;i++){
                      onceleftColIdList=[]
                      oncerightColIdList = []
                          for(let item of _this.onceArr ){
                            if(data.workInputMetaRelationResponses[i].rightDsId.dsId == item.dsId){
                              oncerightTabIdList = item.child;
                            }
                            if(data.workInputMetaRelationResponses[i].leftDsId.dsId == item.dsId){
                              onceLeftTabIdList = item.child
                            }
                          }
                        
                      _this.workInputMetaRelations.unshift({
                          leftDsId: data.workInputMetaRelationResponses[i].leftDsId.dsId,
                          leftTabId: data.workInputMetaRelationResponses[i].leftTabId.tabId,
                          leftColId: data.workInputMetaRelationResponses[i].leftCol.colId,
                          joinCond: data.workInputMetaRelationResponses[i].joinCond,
                          rightColId: data.workInputMetaRelationResponses[i].rightCol.colId,
                          rightTabId:data.workInputMetaRelationResponses[i].rightTabId.tabId,
                          rightDsId: data.workInputMetaRelationResponses[i].rightDsId.dsId,
                          leftDsIdList: newlist,
                          leftTabIdList:onceLeftTabIdList,
                          leftColIdList:data.workInputMetaRelationResponses[i].leftTabId.metadataCols,
                          joinCondList:_this.joinConds,
                          rightColIdList:data.workInputMetaRelationResponses[i].rightTabId.metadataCols,
                          rightTabIdList:oncerightTabIdList,
                          rightDsIdList:newlist
                      })
                    }
              //步骤四回显
              let oncetab={}
              let onceRule={}
              for(let i=0;i<_this.onceArr.length;i++){
                
                for(let j=0;j<data.ruleResponses.length;j++){
                  if(_this.onceArr[i].dsId == data.ruleResponses[j].dsId){
                    for(let c=0;c<_this.onceArr[i].child.length;c++){
                      if(_this.onceArr[i].child[c].tabId == data.ruleResponses[j].tabId){
                        oncetab={}
                        oncetab.dsId=_this.onceArr[i].dsId;
                        oncetab.tabId=data.ruleResponses[j].tabId;
                        oncetab.colId=data.ruleResponses[j].colId;
                        oncetab.colId=data.ruleResponses[j].colId;
                        oncetab.templateId=data.ruleResponses[j].templateId.templateId;
                        oncetab.text=data.ruleResponses[j].templateId.templateName;
                        oncetab.ruleVariables = [];
                        for(let rule of data.ruleResponses[j].ruleVariableResponses){
                          onceRule={};
                          onceRule.label = rule.templateTableInputMetaId.name;
                          onceRule.value = rule.value;
                          onceRule.placeholder = rule.templateTableInputMetaId.promptInfo;
                          onceRule.templateTableInputMetaId = rule.templateTableInputMetaId.id;
                          if(rule.templateTableInputMetaId.inputType == 7){
                            onceRule.disabled = true
                          }
                          oncetab.ruleVariables.push(onceRule)
                        }
                        
                        for(let item of _this.resDatasource){
                             if( oncetab.dsId == item.dsId){
                               _this.onceRuleDialog.list2 = item.metadataTabs;
                               for(let list of item.metadataTabs){
                                if( oncetab.tabId == list.tabId){
                                  _this.onceRuleDialog.list3 = list.metadataCols;
                                    for(let s of list.metadataCols){
                                      if( oncetab.colId == s.colId){
                                        oncetab.colDatatype=s.colDatatype;
                                        oncetab.colDesc=s.colDesc;
                                        oncetab.colName=s.colName;
                                        
                                      }
                                    }
                                  }
                             }
                          }
                        }
                      }
                      _this.onceArr[i].child[c].tableData.push(oncetab)
                    }
                  }
                  
                }
              }
         })
         
         //步骤三回显
         _this.workForm.writeMode = data.writeMode;
         let bbb = []
         for(let list of data.workOutputMetaResponses){
           bbb.push(list.dsId.dsId)
         }
          _this.workForm.tarLib = bbb;
          
        //步骤五回显   
        _this.alarms = JSON.parse(JSON.stringify(data.alarmResponses))
        _this.$axios.GET('/api/v1/core/project/work/selected/get_alarm_form_data').then(function(res) {
          let data = res.data;
          for(let item of  _this.alarms) {
              item.CheckObjectEnum=data.CheckObjectEnum;
              item.AlarmLevelEnum=data.AlarmLevelEnum;
              item.CheckTemplateEnum=data.CheckTemplateEnum;
              item.CompareTypeEnum=data.CompareTypeEnum;
              item.inputText = item.threshold.toString()+"%";
              if (item.checkTemplate < 4) {
                for (let i of item.CompareTypeEnum) {
                  i['disabled'] = true
                }
              } else {  
                for (let i of item.CompareTypeEnum) {
                  delete i['disabled']
                }
              }
          }
        });
        
        
        
       })
       
       
    }
    
   
    //作业触发方式
    this.$axios.GET('/api/v1/core/project/work/selected/get_trigger_method_value').then(function (res) {
      _this.triggerMethodLists = res.data
      let result = Object.keys(_this.triggerMethodLists).map(item => ({
        key: item,
        value: _this.triggerMethodLists[item],
      }))
      _this.workForm.triggerMethod = result[0].value
    })
    //作业调度周期
    this.$axios.GET('/api/v1/core/project/work/selected/get_job_scheduling_value').then(function (res) {
      _this.workCronLists = res.data;
    })
    //作业稽核方式
    this.$axios.GET('/api/v1/core/project/work/selected/get_check_mode_value').then(function (res) {
      let data = res.data
      _this.checkModeLists = data
      let result = Object.keys(_this.checkModeLists).map(item => ({
        key: item,
        value: _this.checkModeLists[item],
      }))
      _this.workForm.checkMode = result[0].value
    })
    //作业输入元连接条件
    this.$axios.GET('/api/v1/core/project/work/selected/get_connection_condition_value').then(function (res) {
      let lists = []
      for (let item in res.data) {
        let list = {}
        list.id = res.data[item]
        list.name = item
        lists.push(list)
      }

      _this.joinConds = lists
    })
    //作业入库方式
    this.$axios.GET('/api/v1/core/project/work/selected/get_warehousing_method_value').then(function (res) {
      _this.writeModeLists = res.data
      //对象转数组
      let result = Object.keys(_this.writeModeLists).map(item => ({
        key: item,
        value: _this.writeModeLists[item],
      }))
      _this.workForm.writeMode = result[0].value
    })

    //获取稽核规则
    this.$axios.GET('/api/v1/core/project/work/get_check_rule').then(function (res) {
      _this.setTree = res.data
      //旧key到新key的映射
      var keyMap = {
        tabDesc: 'text',
        children: 'child',
      }
      var keyMap1 = {
        colName: 'text',
        templates: 'child',
      }
      var keyMap2 = {
        templateName: 'text',
      }
      for (var i = 0; i < _this.setTree.length; i++) {
        var obj = _this.setTree[i]
        for (var key in obj) {
          var newKey = keyMap[key]
          if (newKey) {
            obj[newKey] = obj[key]
            delete obj[key]
          }
        }
        for (var j = 0; j < _this.setTree[i].child.length; j++) {
          var obj1 = _this.setTree[i].child[j]
          for (var key1 in obj1) {
            var newKey1 = keyMap1[key1]
            if (newKey1) {
              obj1[newKey1] = obj1[key1]
              delete obj1[key1]
            }
          }

          for (var k = 0; k < _this.setTree[i].child[j].child.length; k++) {
            var obj2 = _this.setTree[i].child[j].child[k]
            for (var key2 in obj2) {
              var newKey2 = keyMap2[key2]
              if (newKey2) {
                obj2[newKey2] = obj2[key2]
                delete obj2[key2]
              }
            }
          }
        }
      }
    })

  

    //作业警报表单数据
    this.$axios.GET('/api/v1/core/project/work/selected/get_alarm_form_data').then(function (res) {
      let data = res.data
      _this.alarms[0].AlarmLevelEnum = _this.AlarmLevelEnum = data.AlarmLevelEnum
      _this.alarms[0].CheckObjectEnum = _this.CheckObjectEnum = data.CheckObjectEnum
      _this.alarms[0].CheckTemplateEnum = _this.CheckTemplateEnum = data.CheckTemplateEnum
      _this.alarms[0].CompareTypeEnum = _this.CompareTypeEnum = data.CompareTypeEnum
    })
  },
  methods: {
    //规则
    previewRule(item,row){   //预览
      this.onceRuleState = "preview";
      this.dialog.isShow = true;
      this.dialog.rulesDisabled = true;
      this.onceRuleDialog.t1=row.dsId;
      this.onceRuleDialog.t2=row.tabId;
      this.onceRuleDialog.t3=row.colId;
      this.onceRuleDialog.list4=row.ruleVariables;
      
      
    },
    editRule(index,row){   //修改
      this.editIndex = index;
      this.onceRuleState = "edit";
      this.dialog.isShow = true;
      this.dialog.rulesEditDisabled = true;
      this.dialog.rulesDisabled = false;
      this.onceRuleDialog.t1=row.dsId;
      this.onceRuleDialog.t2=row.tabId;
      this.onceRuleDialog.t3=row.colId;
      this.onceRuleDialog.list4=row.ruleVariables;
      this.onceTabId = row.tabId;
      
      if(this.workId){
        this.onceNewTable.tabId = row.tabId;
        this.onceNewTable.dsId = row.dsId;
        this.onceNewTable.colId = row.colId;
        this.onceNewTable.colName = row.colName;
        this.onceNewTable.colDatatype = row.colDatatype;
        this.onceNewTable.colDesc = row.colDesc;
        this.onceNewTable.templateId = row.templateId;
        this.onceNewTable.text = row.text;
      }
      
    },
    deleteRule(item,index){   //删除
      item.splice(index, 1);
    },      
    ruleDialog() {
      this.onceRuleState = "add";
      this.dialog.isShow = true;
      this.dialog.rulesEditDisabled = false;
      this.dialog.rulesDisabled = false;
      this.onceRuleDialog.t1="";
      this.onceRuleDialog.t2="";
      this.onceRuleDialog.t3="";
      this.onceRuleDialog.list4=[];
      
    },
    onceRuleDialogClick1(item){
      if(item.child){
        this.onceRuleDialog.list2 =  item.child;
      }
    },
    onceRuleDialogClick2(item){
      this.onceNewTable.tabId = item.tabId;
      this.onceTabId = item.tabId;
     for(let list of this.workInputMetas){
       if(item.tabId == list.tabId){
          this.onceNewTable.dsId = list.dsId;
          if (list.workInputMetaOutputColsList) {
            this.onceRuleDialog.list3 = list.workInputMetaOutputColsList
          }
       }
     }
    },
    onceRuleDialogClick3(item){
      this.onceNewTable.colDesc = item.colDesc;
      this.onceNewTable.colName = item.colName;
      this.onceNewTable.colId = item.colId;
      this.onceNewTable.colDatatype = item.colDatatype;
    },
    ruleSubmit(){
      this.onceNewTable.ruleVariables = this.onceRuleDialog.list4;
      if(this.onceNewTable.dsId==undefined || this.onceNewTable.tabId==undefined ||this.onceNewTable.colId==undefined){
        this.$message({
          type: 'error',
          message: '请选择库/表/列!',
        })
        return false
      }else if(this.onceNewTable.templateId==undefined){
        this.$message({
          type: 'error',
          message: '请选择规则!',
        })
        return false
      }
      let stade = this.onceRuleState;
      if(stade == "add"){
        for(let item of this.onceArr){
          for(let list of item.child){
            if(this.onceTabId == list.tabId){
             let newInsureDate=Object.assign({},this.onceNewTable)
              list.tableData.push(newInsureDate)
            }
          }
        }
      }else if(stade == "edit"){
       for(let item of this.onceArr){
         for(let list of item.child){
           if(this.onceTabId == list.tabId){
             let newInsureDate=Object.assign({},this.onceNewTable)
              list.tableData[this.editIndex] = newInsureDate
           }
         }
       }
      }
      
      this.dialog.isShow = false;
    },
    //输入配置
    addLibrary0(data, index) {
      for(let list of this.workInputMetas){
        if(list.dsId == "" || list.tabId ==""){
          this.$message({
            type: 'error',
            message: '库或表不能为空!',
          })
          return false
        }
      }
      this.workInputMetas.splice(index + 1, 0, {
        dsId: data.dsId,
        dsDesc: data.dsDesc,
        dsName: data.dsName,
        tabId: '',
        workInputMetaOutputCols: [],
        dsIdList: this.workInputMetas[0].dsIdList,
        tabIdList: data.tabIdList,
        workInputMetaOutputColsList: [],
        dsIdHide: true,
        addHide: true,
        daIdOld :data.dsId
      })
    },
    addLibrary1() {
      for(let list of this.workInputMetas){
        if(list.dsId == "" || list.tabId ==""){
          this.$message({
            type: 'error',
            message: '库或表不能为空!',
          })
          return false
        }
      }
      
      this.workInputMetas.push({
        dsId: '',
        tabId: '',
        workInputMetaOutputCols: [],
        dsIdList: this.workInputMetas[0].dsIdList,
        tabIdList: [],
        workInputMetaOutputColsList: [],
        newLibrary:true
      })
    },
    removeLibrary(item,index) {
      //删除
      if(this.workInputMetas[index].newLibrary == true){
        if(index < this.workInputMetas.length -1){
         if(this.workInputMetas[index].dsId == this.workInputMetas[index+1].dsId){
           this.$message({
             type: 'error',
             message: '请先删除其他关联关系!'
           });
           return false
         }
        }
      }
      
      if (this.workInputMetas.length > 1) {
        const index = this.workInputMetas.indexOf(item)
        if (index !== -1) {
          this.workInputMetas.splice(index, 1)
        }
        this.onceArr = JSON.parse(JSON.stringify(this.workInputMetas))
        var onceA = []
           for(let item of this.onceArr){
             item.child =[{
               tabId:item.tabId,
               tabDesc:item.tabDesc,
               tableData:[]
             }]
           }
           for (var i = 0, len = this.onceArr.length; i < len; i++) {
             for (var j = i + 1, len = this.onceArr.length; j < len; j++) {
               if (this.onceArr[i].dsId === this.onceArr[j].dsId) {
                onceA={
                  tabId:this.onceArr[j].tabId,
                  tabDesc:this.onceArr[j].tabDesc,
                  tableData:[]
                }
                this.onceArr[i]["child"].push(onceA);
                this.onceArr.splice(j, 1);
                j--; // 每删除一个数j的值就减1
                len--; // j值减小时len也要相应减1（减少循环次数，节省性能）
               }
             }
           }
       this.workInputMetaRelations=[];
               var newlist = [];
               this.onceArr.map(function(items) {
                 newlist.push({
                   dsId:items.dsId,
                   dsDesc:items.dsDesc
                 })
               });
                let workInputMetasLength = this.workInputMetas.length
               for(let i=0;i<workInputMetasLength - 1;i++){
                 this.workInputMetaRelations.push({
                     leftDsId: "",
                     leftTabId: "",
                     leftColId: "",
                     joinCond: "",
                     rightColId: "",
                     rightTabId: "",
                     rightDsId: "",
                     leftDsIdList: newlist,
                     leftTabIdList:[],
                     leftColIdList: [],
                     joinCondList:this.joinConds,
                     rightColIdList:[ ],
                     rightTabIdList: [],
                     rightDsIdList:newlist
                 })
               }
      }
      if (this.workInputMetas.length == 1) {
        this.workInputMetaRelations = []
      }
      
      this.onceLib=[];
      this.onceTab=[];
      for(let s of this.workInputMetas){
        this.onceLib.push(s.dsId)
        this.onceTab.push(s.tabId)
      }
      for(let s of this.workInputMetas){
         for(let list of s.dsIdList){
            list.disabled = false
              for(let item of this.onceLib){
                  if(list.dsId ==item){
                    list.disabled = true
                  }
              }
         }
         for(let list of s.tabIdList){
            list.disabled = false
              for(let item of this.onceTab){
                  if(list.tabId ==item){
                    list.disabled = true
                  }
              }
         }
      }
    },
    dsIdListClick(item, list) {
      //库点击
      if(item.tabId != ""){ //说明已经选择了 表， 需要清空表、列
        item.tabId="";
        item.tabDesc="";
        item.workInputMetaOutputCols=[];
        item.workInputMetaOutputColsList=[];
      }
     item.dsDesc = list.dsDesc;
     item.tabIdList = list.metadataTabs;
     if(item.daIdOld == undefined){
        item.daIdOld = item.dsId;
     }else if(item.daIdOld != item.dsId){
       for(let i=this.workInputMetas.length-1;i >= 0;i--){
           if(this.workInputMetas[i].dsId == item.daIdOld){
             this.workInputMetas.splice(i,1);
           }
        }
        item.daIdOld = item.dsId;
     }
     this.onceLib=[]
     for(let s of this.workInputMetas){
       this.onceLib.push(s.dsId)
     }
     for(let s of this.workInputMetas){
        for(let list of s.dsIdList){
           list.disabled = false
             for(let item of this.onceLib){
                 if(list.dsId ==item){
                   list.disabled = true
                 }
             }
        }
     }
     this.onceTab=[]
     for(let s of this.workInputMetas){
       this.onceTab.push(s.tabId)
     }
     for(let s of this.workInputMetas){
        for(let list of s.tabIdList){
           list.disabled = false
             for(let item of this.onceTab){
                 if(list.tabId ==item){
                   list.disabled = true
                 }
             }
        }
     }
     
     
    },
    tabIdListChange(item, index){
      this.onceTab=[]
      for(let s of this.workInputMetas){
        this.onceTab.push(s.tabId)
        
      }
      for(let s of this.workInputMetas){
         for(let list of s.tabIdList){
            list.disabled = false
              for(let item of this.onceTab){
                  if(list.tabId ==item){
                    list.disabled = true
                  }
              }
         }
      }
    },
    tabIdListClick(item, list) {
      //表点击
      item.workInputMetaOutputCols=[]
      item.tabDesc = list.tabDesc
      item.workInputMetaOutputColsList = list.metadataCols
    let workInputMetasLength = this.workInputMetas.length
        this.onceArr = JSON.parse(JSON.stringify(this.workInputMetas))
        var onceA = []
           for(let item of this.onceArr){
             item.child =[{
               tabId:item.tabId,
               tabDesc:item.tabDesc,
               tableData:[]
             }]
           }
                 for (var i = 0, len = this.onceArr.length; i < len; i++) {
                   for (var j = i + 1, len = this.onceArr.length; j < len; j++) {
                     if (this.onceArr[i].dsId === this.onceArr[j].dsId) {
                      onceA={
                        tabId:this.onceArr[j].tabId,
                        tabDesc:this.onceArr[j].tabDesc,
                        tableData:[]
                      }
                      this.onceArr[i]["child"].push(onceA);
                      this.onceArr.splice(j, 1);
                      j--; // 每删除一个数j的值就减1
                      len--; // j值减小时len也要相应减1（减少循环次数，节省性能）
                     }
                   }
                 }
           this.workInputMetaRelations=[];
           var newlist = [];
           this.onceArr.map(function(items) {
             newlist.push({
               dsId:items.dsId,
               dsDesc:items.dsDesc
             })
           });
           
           for(let i=0;i<workInputMetasLength - 1;i++){
             
             this.workInputMetaRelations.push({
                 leftDsId: "",
                 leftTabId: "",
                 leftColId: "",
                 joinCond: "",
                 rightColId: "",
                 rightTabId: "",
                 rightDsId: "",
                 leftDsIdList: newlist,
                 leftTabIdList:[],
                 leftColIdList: [],
                 joinCondList:this.joinConds,
                 rightColIdList:[],
                 rightTabIdList: [],
                 rightDsIdList:newlist
             })
           }
    },
    dsIdClick(data, list, type) {
      if (type == 'lt') {
        data.leftTabIdList = []
        for(let item of this.onceArr){
          if(list.dsId == item.dsId){
             if (item.child) {
               data.leftTabIdList = item.child
             }
          }
        }
        
      } else {
        data.rightTabIdList = []
        data.rightTabIdList = []
        for(let item of this.onceArr){
          if(list.dsId == item.dsId){
             if (item.child) {
               data.rightTabIdList = item.child
             } 
             
          }
        }
      }
    },
    timeChange(time){
      let onceTime = []
      for (var item of time) {
        //object 说明是中国标准时间 （未转换）
        onceTime.push(item.getFullYear() + '-' + (item.getMonth() + 1) + '-' + item.getDate() + ' ' + item.getHours() + ':' + item.getMinutes() + ':' + item.getSeconds())
      }
      this.allDatas['startTime'] = onceTime[0];
      this.allDatas['endTime'] = onceTime[1];
    },
    tabIdClick(data, list, type) {
      if (type == 'lt') {
        data.leftColIdList = []
        for(let item of this.workInputMetas){
          if(list.tabId == item.tabId){
             if (item.workInputMetaOutputColsList) {
               data.leftColIdList = item.workInputMetaOutputColsList
             }
          }
        }
      } else {
        data.rightColIdList = []
        for(let item of this.workInputMetas){
          if(list.tabId == item.tabId){
             if (item.workInputMetaOutputColsList) {
               data.rightColIdList = item.workInputMetaOutputColsList
             }
          }
        }
      }
    },
    //告警规则
    TemplateChange(domain) {
      if (domain.checkObject == 1) {
        if (domain.checkTemplate == 5) {
          //此刻选中百分比 则 重置
          domain.checkTemplate = ''
        }
        domain.CheckTemplateEnum[4] = { CODE: 5, LABEL: '百分比', disabled: true }
      } else {
        domain.CheckTemplateEnum[4] = { CODE: 5, LABEL: '百分比' }
      }
    },
    ObjectChange(domain) {
      domain.compareType = ''
      domain.inputText = ''
      if (domain.checkTemplate < 4) {
        for (let i of domain.CompareTypeEnum) {
          i['disabled'] = true
        }
      } else {
        for (let i of domain.CompareTypeEnum) {
          delete i['disabled']
        }
      }
    },
    thresholdBlur(domain) {
      if (domain.checkTemplate != 4) {
        if (domain.inputText) {
          domain.inputText = domain.inputText + '%'
        }
      }
    },
    thresholdFocus(domain) {
      if (domain.checkTemplate != 4) {
        domain.inputText = domain.inputText.substr(0, domain.inputText.length - 1)
      }
    },
    removeDomain(item) {
      const index = this.alarms.indexOf(item)
      if (index !== -1) {
        this.alarms.splice(index, 1)
      }
    },
    addDomain() {
      this.alarms.push({
        checkObject: '', //校验模板
        threshold:"",
        inputText: '', //阈值
        checkTemplate: '', //校验条件
        compareType: '', // 比对条件
        alarmLevel: '', //告警级别
        autoProblem: false, //自动生成质量问题
        CheckObjectEnum: this.CheckObjectEnum,
        CheckTemplateEnum: this.CheckTemplateEnum,
        CompareTypeEnum: this.CompareTypeEnum,
        AlarmLevelEnum: this.AlarmLevelEnum,
      })
    },
    //返回上一级
    goBack() {
      this.$router.push({
        name: 'projectDetails',
        query: {
          projectId: this.projectId,
        },
      })
    },
    prev() {
      //上一步
      this.active--
    },
    next() {
      //下一步
      var _this = this
      if (_this.active == 1) {
        if (_this.workForm.workName == '') {
          _this.$message({
            type: 'error',
            message: '作业名称不能为空!',
          })
          return false
        }
        if (/^[a-zA-Z_0-9\u4e00-\u9fa5-]{3,50}$/.test(_this.workForm.workName) == false) {
          _this.$message({
            type: 'error',
            message: '作业名称只能填写中英文字母数字下划线3-50个字符!',
          })
          return false
        }
       
        if (_this.istriggerMethod == true) {
          if (_this.workForm.workCron == '') {
             _this.$message({
               type: 'error',
               message: '作业调度周期为空!',
             })
             return false
           }
           if (_this.workForm.timeCond == '') {
             _this.$message({
               type: 'error',
               message: '作业时间不能为空!',
             })
             return false
           }
            switch(_this.workForm.workCron) {
                 case 2001:
                    _this.workForm.workCronCn = "5分钟调度一次";
                    break;
                 case 2002:
                    _this.workForm.workCronCn = "10分钟调度一次";
                    break;
                case 2003:
                  _this.workForm.workCronCn = "15分钟调度一次";
                   break;
                case 2004:
                 _this.workForm.workCronCn = "20钟调度一次";
                case 2005:
                 _this.workForm.workCronCn = "30分钟调度一次";
                case 2006:
                 _this.workForm.workCronCn = "1小时调度一次";
                case 2007:
                 _this.workForm.workCronCn = "6小时调度一次";
                 break;
            } 
        }
        _this.allDatas['workCron'] = _this.workForm.workCron;
        _this.active++;
      } else if (_this.active == 2) {
        var Required = true //验证是否填写整
        for(let list of this.workInputMetas){
          if(list.dsId == "" || list.tabId ==""){
            this.$message({
              type: 'error',
              message: '库或表不能为空!',
            })
            return false
          }
        }
        
        if(this.workInputMetaRelations.length>0){
          for(let list of this.workInputMetaRelations){
            if(list.leftDsId == "" || list.leftTabId ==""|| list.leftColId ==""|| list.rightDsId ==""|| list.rightTabId ==""|| list.rightColId ==""){
              _this.$message({
                type: 'error',
                message: '库或表不能为空!',
              })
              return false
            }else if(list.joinCond =="") {
              _this.$message({
                type: 'error',
                message: '请选择连接关系!',
              })
              return false
            }
          }
        }



        if (Required) {
          //第六部 预览用
          let wList = []
          let onceC = {}
          for (let i = 0; i < this.workInputMetas.length; i++) {
            wList = []
            this.workInputMetas[i].workInputMetaOutputColsCn = []
            for (let s of this.workInputMetas[i].workInputMetaOutputCols) {
              onceC = {};
              for(let list of this.workInputMetas[i].workInputMetaOutputColsList){
                if(s == list.colId){
                  onceC.colDesc = list.colDesc;
                  onceC.colName = list.colName;
                }
              }
              wList.push(onceC);
            }
            this.workInputMetas[i].workInputMetaOutputColsCn = wList;
          }
          
          for(let item of this.workInputMetaRelations){
              for(let list of item.leftDsIdList){
                if(item.leftDsId==list.dsId){
                  item.leftdsDesc = list.dsDesc
                }
              }
              for(let list of item.leftTabIdList){
                if(item.leftTabId==list.tabId){
                  item.lefttabDesc = list.tabDesc
                }
              }
              for(let list of item.leftColIdList){
                if(item.leftColId==list.colId){
                  item.leftcolName = list.colName;
                  item.leftcolDesc = list.colDesc;
                }
              }
           
            for(let list of item.joinCondList){
              if(item.joinCond==list.id){
                item.joinCondName = list.name
              }
            }
            for(let list of item.rightDsIdList){
              if(item.rightDsId==list.dsId){
                item.rightdsDesc = list.dsDesc
              }
            }
           for(let list of item.rightTabIdList){
             if(item.rightTabId==list.tabId){
               item.righttabDesc = list.tabDesc
             }
           }
           for(let list of item.rightColIdList){
             if(item.rightColId==list.colId){
               item.rightcolName = list.colName;
               item.rightcolDesc = list.colDesc;
             }
           }
          }
          _this.active++;
        }
      } else if (_this.active == 3) {
        
        if (_this.workForm.tarLib.length == 0) {
          _this.$message({
            type: 'error',
            message: '请选择目标库!',
          })
          return false
        } else {
          let workOutputMetas = []
          let workOutputMeta = {}
          let onceaudi={}
          for(let item of _this.workForm.tarLib){
            workOutputMeta = {}
            for(let list of _this.auditLists){
              if(item == list.dsId){
                workOutputMeta["dsDesc"] = list.dsDesc;
              }
            }
            workOutputMeta['dsId'] = item
            workOutputMetas.push(workOutputMeta)
          }
          
          _this.allDatas['workOutputMetas'] = workOutputMetas;
          _this.active++;
          _this.ruleTabRadio = _this.onceArr[0].dsId;
          _this.activeName = _this.ruleTabRadio;
          
        }
      } else if (_this.active == 4) {
         let newOnceArr=JSON.parse(JSON.stringify(_this.onceArr))
        let newRules = []
        for(let item of newOnceArr){
          for(let list of item.child){
              for(let list1 of list.tableData){
                  newRules.push(list1)
              }
          }
        }
        if (newRules.length == 0) {
          _this.$message({
            type: 'error',
            message: '规则不能为空!',
          })
          return false
        } else {
          _this.allDatas.rules = newRules;
          _this.active++
        }
      }else if (_this.active == 5) {
         var _this = this;
         
        for (let a of _this.alarms) {
          if (a.alarmLevel == '' || a.checkObject == '' || a.checkTemplate == '' || a.inputText == '') {
            _this.$message({
              type: 'error',
              message: '请填写完整！',
            })
            Required = false
            return false
          }
          if (a.checkObject > 3) {
            if (a.compareType == '') {
              _this.$message({
                type: 'error',
                message: '请填写完整！',
              })
              Required = false
              return false
            }
          }
          a.threshold = a.inputText
          if (a.threshold.includes('%')) {
            a.threshold=a.threshold.slice(0, -1)
          }
          for(let item of a.CheckObjectEnum){
            if(a.checkObject == item.CODE){
              a.checkObjectCn = item.LABEL
            }
          }
          for(let item of a.CheckTemplateEnum){
            if(a.checkTemplate == item.CODE){
              a.checkTemplateCn = item.LABEL
            }
          }
          if(a.compareType){
            for(let item of a.CompareTypeEnum){
              if(a.compareType == item.CODE){
                a.compareTypeCn = item.LABEL
              }
            }
          }else{
            a.compareTypeCn = "无"
          }
          
          for(let item of a.AlarmLevelEnum){
            if(a.alarmLevel == item.CODE){
              a.alarmLevelCn = item.LABEL
            }
          }
          
          a.threshold = Number(a.threshold)
          a.autoProblem = Number(a.autoProblem);
          if(a.autoProblem == 1){
            a.autoProblemCn = "是"
          }else{
            a.autoProblemCn = "否"
          }
          
        }
         _this.active++;
      }
    },
    postData() {
      var _this = this
      var Required = true
     
      if (Required) {
        _this.allDatas['alarms'] = _this.alarms;
        _this.allDatas['workName'] = _this.workForm.workName;
        _this.allDatas['triggerMethod'] = _this.workForm.triggerMethod;
        _this.allDatas['checkMode'] = _this.workForm.checkMode;
        _this.allDatas['writeMode'] = _this.workForm.writeMode;
        
        let wList = [];
        let onceC = {};
        for (let i = 0; i < this.workInputMetas.length; i++) {
          wList = []
          for (let s of this.workInputMetas[i].workInputMetaOutputCols) {
            onceC = {};
            onceC.colId = s;
            wList.push(onceC);
          }
          this.workInputMetas[i].workInputMetaOutputCols = wList
        }
        _this.allDatas.workInputMetas = _this.workInputMetas;
        _this.allDatas.workInputMetaRelations = _this.workInputMetaRelations;
        if (_this.projectId != '') {
          _this.allDatas['projectId'] = _this.projectId
          this.$axios.PUT('/api/v1/core/project/work', _this.allDatas).then(function (res) {
            if (res.code == 200) {
              if(_this.workId== undefined){
                _this.$message({
                  type: 'success',
                  message: '新建作业成功!',
                })
                _this.$router.push({
                  name: 'projectDetails',
                  query: {
                    projectId: _this.projectId,
                  },
                })
              }else{
                _this.$message({
                  type: 'success',
                  message: '修改作业成功!',
                })
                _this.$router.push({
                  name: 'projectDetails',
                  query: {
                    projectId: _this.projectId,
                  },
                })
              }
              
             
            } else {
              _this.$message({
                type: 'success',
                message: res.message,
              })
            }
          })
        } else {
          _this.$message({
            type: 'error',
            message: '作业主键不能为空!',
          })
        }
      }
    },
    handleNodeClick(data) {
      this.onceRuleDialog.list4=[]
      let list4Arr ={}
      if (data.templateDynamicParameter) {
         this.onceNewTable.templateId = data.templateId;
         this.onceNewTable.text = data.text;
          if (data.templateDynamicParameter != '') {
            for (var i = 0; i < data.templateDynamicParameter.length; i++) {
              if (data.templateDynamicParameter[i].defaultValues != '') {
              } else if (data.templateDynamicParameter[i].defaultValue != '') {
                 list4Arr ={}
                 list4Arr.templateTableInputMetaId = data.templateDynamicParameter[i].id;
                 list4Arr.label = data.templateDynamicParameter[i].name;
                 list4Arr.value = data.templateDynamicParameter[i].defaultValue;
                 list4Arr.placeholder = data.templateDynamicParameter[i].promptInfo;
                
                 
                let list = data.templateDynamicParameter[i];
                if (list.isModify == false) {
                    list4Arr.disabled = true
                } else {
                   list4Arr.disabled = false
                }
                 this.onceRuleDialog.list4.push(list4Arr)
              } else if (data.templateDynamicParameter[i].promptInfo != null) {
                  list4Arr ={}
                  list4Arr.templateTableInputMetaId = data.templateDynamicParameter[i].id;
                  list4Arr.label = data.templateDynamicParameter[i].name;
                  list4Arr.value = "";
                  list4Arr.placeholder = data.templateDynamicParameter[i].promptInfo;
                  this.onceRuleDialog.list4.push(list4Arr)
                  
              }
            }
          }
      }
    },
    // 调度周期、生效时间 显示 隐藏 清空 周期、时间
    gettriggerMethod() {
      this.istriggerMethod = !this.istriggerMethod;
      this.workForm.workCron = "";
      this.workForm.timeCond=[];
    },

    //验证分步 是否填写完整 否则不可以下一步
    verification(workForm) {
      this.$refs[workForm].validate(valid => {
        if (valid) {
          return true
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
.step {
  width: 90%;
  margin: 0 auto;
}

.step_form {
  width: 98%;
  margin: 10px auto;
}

.el-step__icon {
  display: flex;
}

.stepBtn {
  text-align: center;
}

.form3 .el-tree {
  width: 200px;
}

#relationModal label {
  width: 80px;
  margin: 0;
}

#relationBody > div {
  margin-bottom: 10px;
}

.modal-body {
  height: calc(100vh - 190px);
}

.el-tree {
  width: 200px;
  margin-right: 10px;
  overflow: auto;
}

.box1 .checkbox {
  width: 320px;
  overflow: auto;
  height: 55px;
  margin-right: 10px;
}


.page .newStyle .tabCol > div:nth-child(1) {
  width: 280px;
}

.page .newStyle .tabCol > div:nth-child(2) {
  flex: 1;
  display: flex;
}

.page .newStyle .tabCol > div:nth-child(2) > div {
  flex: 1;
}

.page .newStyle .tabCol > div:nth-child(3) {
  width: 120px;
}

/* 必填 样式 */
.form0.is-required :deep(label::before),
.form2.is-required :deep(.el-form-item__label::before),
#relationModal.is-required :deep(label::before),
.form1 .is-required :deep(label::before) {
  content: '*';
  color: #f56c6c;
  margin-right: 4px;
}

.workInputMetas :deep(.div1 .el-form-item__content),
.workInputMetaRelations :deep(.el-form-item__content) {
  display: flex;
  align-items: center;
}

.workInputMetas :deep(.div1 .el-form-item__content > div),
.workInputMetaRelations :deep(.el-form-item__content > div) {
  margin-right: 10px;
}

.workInputMetas :deep(.isHide) {
  opacity: 0;
  pointer-events: none;
}

.form4 .rules {
  padding: 5px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form4 .rules :deep(.el-form-item__content) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}

.form4 .rules :deep(.el-form-item .el-form-item__content .el-input) {
  width: auto;
}

.form4 .rules :deep(.el-form-item .el-form-item__content > div) {
  margin-right: 10px;
}

.form4 .rules :deep(.el-form-item .el-form-item__content .el-switch) {
  display: block;
}

.form4 .rules .rules_div {
  display: flex;
}

.form4 .rules .rules_div > div {
  text-align: center;
  font-size: 18px;
  margin: 12px 10px 12px 0;
}

.form4 .rules .el-form-item__content > div {
  text-align: center;
}

.form4 .rules .rules_div > div:nth-child(1),
.form4 .rules .el-form-item__content > div:nth-child(1) {
  width: 50px;
}

.form4 .rules .rules_div > div:not(:first-child),
.form4 .rules .el-form-item__content > div:not(:first-child) {
  flex: 1;
}

.form4 .rules .rules_div > div:nth-child(8),
.form4 .rules .el-form-item__content > div:nth-child(8) {
  margin-right: 0;
}

.form5 .workDetails {
  height: auto;
}

.form5 .workDetails .el-col {
  min-height: 50px;
}

.form5 .workDetails .el-col > div > span {
  margin-right: 15px;
}

.form5 .workDetails .el-col > div > div {
  color: #000;
  font-weight: 700;
}
</style>
